import { TRANSLATIONS } from '../settings';

export const scrollToElemTargetId = (elem) => {
    if (elem && typeof window !== 'undefined' && window.location.hash) {
        let locationHash = window.location.hash.replace('#', '');

        if (locationHash === elem.id) {
            elem.scrollIntoView({ behavior: 'smooth' });
        }
    }
};

export const capitalize = (expression) => expression.charAt(0).toUpperCase() + expression.slice(1);

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb#answer-39077686
export const hexToRgb = (hex) =>
    hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g)
        .map((x) => parseInt(x, 16));

export function getTranslatedCountryTitle(currientCountry, lang, translateList) {
    if (!currientCountry || !translateList) {
        return 'Russia';
    }

    for (let inner_list of translateList) {
        if (inner_list.country === currientCountry) {
            switch (lang) {
                case 'kz':
                    return inner_list.country_kz;
                case 'en':
                    return inner_list.country_en;
                default:
                    return currientCountry;
            }
        }
    }

    return currientCountry;
}

export const getTitle = (array, langCurrent) => {
    if (!array || !array.length) {
        return '';
    }

    const element = array.find((item) => item.lang === langCurrent);

    return element?.title || '';
};

export function getTranslatedCityTitle(currientCity, lang, translateList) {
    if (!currientCity || !translateList) {
        return 'Moscow';
    }

    for (let inner_list of translateList) {
        for (let translate of inner_list.city) {
            if (translate.title === currientCity) {
                return getTitle(inner_list.city, lang);
            }
        }
    }

    return currientCity;
}

export function getTranslatedDepartamentTitle(currientDep, lang, translateList) {
    if (!currientDep || !translateList) {
        return 'Dev';
    }

    for (let inner_list of translateList) {
        if (inner_list.name === currientDep) {
            switch (lang) {
                case 'kz':
                    return inner_list.name_kz;
                case 'en':
                    return inner_list.name_en;
                default:
                    return currientDep;
            }
        }
    }

    return currientDep;
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

export function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const prettify = (num) => {
    return num > 999 ? num.toString().replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ') : num;
};

export const prettifyLegend = (num, currentLang) => {
    if (num > 1000000) {
        return `${num / 1000000} ${TRANSLATIONS.calc.legend_mln[currentLang]}`;
    } else {
        return `${num / 1000} ${TRANSLATIONS.calc.legend_th[currentLang]}`;
    }
};

export const sillyMonthControl = (n) => {
    const textForms = [
        {
            ru: 'месяца',
            en: 'months',
            kz: 'ай',
        },
        {
            ru: 'месяцев',
            en: 'months',
            kz: 'ай',
        },
    ];

    if (n === 2) {
        // index in terms array
        return textForms[0];
    }
    return textForms[1];
};

export const activateSubscribeMessage = (lang, type) => {
    let activateText = '';

    switch (type) {
        case 'emailexist':
            activateText = {
                ru: 'Данный email уже существует',
                en: 'This email already exists',
                kz: 'Бұл электрондық пошта бұрыннан бар',
            };
            break;

        case 'done':
            activateText = {
                ru: 'Вы успешно подтвердили email',
                en: 'You have successfully confirmed your email',
                kz: 'Электрондық поштаңыз сәтті расталды',
            };
            break;

        case 'notprod':
            activateText = {
                ru: 'ru notprod',
                en: 'en notprod',
                kz: 'kz notprod',
            };
            break;

        case 'fail':
            activateText = {
                ru: 'Извините, данный сервис временно недоступен',
                en: 'Sorry, this service is temporarily unavailable',
                kz: 'Кешіріңіз, бұл қызмет уақытша қол жетімді емес',
            };
            break;

        default:
            activateText = {
                ru: 'Извините, данный сервис временно недоступен',
                en: 'Sorry, this service is temporarily unavailable',
                kz: 'Кешіріңіз, бұл қызмет уақытша қол жетімді емес',
            };
            break;
    }

    return activateText[lang];
};
