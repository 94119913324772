import React from "react";
import { useSelector } from "react-redux";
import { Link } from "gatsby";
import styled from "styled-components";

import { BREAKPOINTS, SETTINGS, COLORS } from "../../../settings";
import { addItemClass } from "../../../helpers/nav";
import { useMenuFooter } from "../../../hooks/use-menu-footer";
import { useMenuSidebar } from "../../../hooks/use-menu-sidebar";
import { ButtonSubmenuOpen } from "./ButtonSubmenuOpen";
import { langGetNavItems } from "../../../helpers/lang";

export const MenuFooter = () => {
  const dataBase = useSelector(state => state.base);
  const {
    lang: { current: langCurrent },
  } = dataBase;

  const itemsAll = useMenuFooter();
  const itemsAllMobile = useMenuSidebar();
  const column1Items = langGetNavItems(itemsAll, "column1", langCurrent);
  const column2Items = langGetNavItems(itemsAll, "column2", langCurrent);
  const column3Items = langGetNavItems(itemsAll, "column3", langCurrent);
  const baseItems = langGetNavItems(itemsAllMobile, "base", langCurrent);
  const additionalItems = langGetNavItems(
    itemsAllMobile,
    "additional",
    langCurrent
  );

  const submenuIsOpen = (currentId, openedId) => currentId === openedId;
  const getPath = menuItem => menuItem.url.replace(SETTINGS.URL_MASTER, "");

  return (
    <StyledMenu className="menu-footer">
      {column1Items && (
        <ul className="menu menu-footer_column">
          {column1Items.map(item => {
            const path = item.url.replace(SETTINGS.URL_MASTER, "");

            return (
              <li key={item.wordpress_id} className="menu_item">
                <Link
                  to={path}
                  className="menu_link"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              </li>
            );
          })}
        </ul>
      )}

      {column2Items && (
        <ul className="menu menu-footer_column">
          {column2Items.map(item => {
            const path = item.url.replace(SETTINGS.URL_MASTER, "");

            return (
              <li key={item.wordpress_id} className="menu_item">
                <Link
                  to={path}
                  className="menu_link"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              </li>
            );
          })}
        </ul>
      )}

      {column3Items && (
        <ul className="menu menu-footer_column">
          {column3Items.map(item => {
            const path = item.url.replace(SETTINGS.URL_MASTER, "");

            return (
              <li key={item.wordpress_id} className="menu_item">
                <Link
                  to={path}
                  className="menu_link"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              </li>
            );
          })}
        </ul>
      )}

      {baseItems && (
        <ul className="menu menu-footer_base-mobile">
          {baseItems.map(item => {
            const path = getPath(item);
            const haveChildren = !!item.wordpress_children;
            const submenuClass = submenuIsOpen(
              item.wordpress_id,
              dataBase.footer.subnavOpen
            )
              ? " menu_submenu__open"
              : "";

            return (
              <li
                key={item.wordpress_id}
                className={addItemClass(
                  haveChildren
                  // submenuIsOpen(item.wordpress_id, dataBase.footer.subnavOpen)
                )}
              >
                <Link
                  to={path}
                  className="menu_link"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                {haveChildren && (
                  <ButtonSubmenuOpen
                    folderId={item.wordpress_id}
                    folderIsOpen={submenuIsOpen(
                      item.wordpress_id,
                      dataBase.footer.subnavOpen
                    )}
                  />
                )}
                {haveChildren && (
                  <ul className={"menu_submenu" + submenuClass}>
                    {item.wordpress_children.map(subItem => {
                      const path = getPath(subItem);

                      return (
                        <li key={subItem.wordpress_id} className="menu_item">
                          <Link
                            to={path}
                            className="menu_link"
                            dangerouslySetInnerHTML={{ __html: subItem.title }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      )}

      {additionalItems && (
        <ul className="menu menu-footer_additional-mobile">
          {additionalItems.map(item => (
            <li key={item.wordpress_id} className="menu_item">
              <Link
                to={item.url.replace(SETTINGS.URL_MASTER, "")}
                className="menu_link"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </li>
          ))}
        </ul>
      )}
    </StyledMenu>
  );
};

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    flex-direction: row;
  }

  .menu-footer_column {
    display: none;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: block;
    }

    + .menu-footer_column {
      margin: 0 0 0 30px;

      @media (min-width: ${BREAKPOINTS.desktop_lg + "px"}) {
        margin: 0 0 0 45px;
      }
    }
  }

  .menu_item {
    font-size: 16px;
    line-height: 25px;

    + .menu_item {
      margin: 10px 0 0;
    }
  }

  .menu_link {
    color: ${COLORS.text};
  }

  .menu-footer_base-mobile {
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: none;
    }

    > .menu_item {
      > .menu_link {
        font-size: 25px;
        line-height: 30px;
        font-weight: 700;
      }

      + .menu_item {
        margin: 20px 0 0;
      }
    }

    .menu_item__folder {
      display: flex;
      flex-wrap: wrap;
    }

    .menu_submenu {
      display: none;
      width: 100%;
      margin: 10px 0 0;

      &.menu_submenu__open {
        display: block;
      }
    }
  }

  .menu-footer_additional-mobile {
    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      display: none;
    }

    .menu_item {
      margin: 20px 0 0;
    }
  }
`;
