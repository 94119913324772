import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "gatsby";
import styled from "styled-components";

import { COLORS, BREAKPOINTS } from "../../../settings";
import { langGetName, langLinkClass } from "../../../helpers/lang";
import { updateLangListState } from "../../../store/actions/base";

export const Lang = () => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const {
    lang: { current: langCurrent },
    pages: { isPageMain, isPageError },
  } = dataBase;

  const linkProps = {
    className: langLinkClass(langCurrent, dataBase.lang.langCode),
  };

  const clickHandler = () => {
    updateLangListState(dispatch);
  };

  return langCurrent !== null && isPageMain !== null && !isPageError ? (
    <div className="lang-switcher">
      <StyledButton
        type="button"
        onClick={() => clickHandler()}
        className="lang-wrapper"
        isLightHeader={dataBase.isLightHeader}
        isSticky={dataBase.isNavSticky}
        aria-label={langGetName(dataBase.lang)}
      >
        {langCurrent.toUpperCase()}
      </StyledButton>
      <StyledNewLangList
        className="lang-list"
        isOpen={dataBase.langList.listOpen}
      >
        {dataBase.lang.all &&
          dataBase.lang.all.map((lang, i) => {
            return lang.link ? (
              <li key={i}>
                <Link
                  onClick={() => clickHandler()}
                  to={lang.link.replace("/wp/", "/")}
                  {...linkProps}
                >
                  {lang.langCode}
                </Link>
              </li>
            ) : null;
          })}
      </StyledNewLangList>
    </div>
  ) : null;
};

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0;
  margin: 0 0 0 20px;
  border: none;
  color: ${props =>
    props.isLightHeader && !props.isSticky
      ? COLORS.text_inverted
      : COLORS.text};
  font-size: 16px;
  line-height: 25px;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    margin: 0 0 0 40px;
  }
`;

const StyledNewLangList = styled.ul`
  position: absolute;
  display: ${props => (props.isOpen ? "block" : "none")};
  margin: 10px 0 0 26px;
  padding: 0;
  list-style: none;
  background-color: white;
  box-shadow: 0px 0px 8px 0px rgba(45, 59, 77, 0.5);
  border-radius: 5px;
  @media (max-width: 768px) {
    margin: 10px 0 0 7px;
  }

  > li a {
    display: inline-block;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 25px;
    text-transform: uppercase;
    color: ${COLORS.text};
    border-radius: 5px;

    &:hover {
      text-decoration: none;
      background-color: #f2f2f2;
    }
  }
`;
