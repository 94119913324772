import { useStaticQuery, graphql } from "gatsby";

export const useThemeSettings = () => {
  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query ThemeSettings {
        wordpressAcfOptions {
          options {
            logo_light {
              localFile {
                publicURL
              }
            }
            logo_dark {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `
  );

  return wordpressAcfOptions.options;
};
