import { useStaticQuery, graphql } from "gatsby";

export const useMenuSidebar = () => {
  const {
    baseRu,
    baseEn,
    baseKz,
    additionalRu,
    additionalEn,
    additionalKz,
  } = useStaticQuery(
    graphql`
      query MenuSidebar {
        baseRu: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 59 }) {
          name
          items {
            url
            title
            wordpress_id
            wordpress_children {
              url
              title
              wordpress_id
            }
          }
        }
        baseEn: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 75 }) {
          name
          items {
            url
            title
            wordpress_id
            wordpress_children {
              url
              title
              wordpress_id
            }
          }
        }
        baseKz: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 74 }) {
          name
          items {
            url
            title
            wordpress_id
            wordpress_children {
              url
              title
              wordpress_id
            }
          }
        }

        additionalRu: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 60 }) {
          name
          items {
            url
            title
            wordpress_id
          }
        }
        additionalEn: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 77 }) {
          name
          items {
            url
            title
            wordpress_id
          }
        }
        additionalKz: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 76 }) {
          name
          items {
            url
            title
            wordpress_id
          }
        }
      }
    `
  );

  const baseRuItems = baseRu.items;
  const baseEnItems = baseEn.items;
  const baseKzItems = baseKz.items;
  const additionalRuItems = additionalRu.items;
  const additionalEnItems = additionalEn.items;
  const additionalKzItems = additionalKz.items;

  return {
    baseRuItems,
    baseEnItems,
    baseKzItems,
    additionalRuItems,
    additionalEnItems,
    additionalKzItems,
  };
};
