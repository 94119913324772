import React from "react";
import { useSelector } from "react-redux";
import { Link } from "gatsby";
import styled from "styled-components";
import { BREAKPOINTS, COLORS } from "../../settings";
import { translations } from "./translations";
import { Logo } from "../Logo";
import { MenuFooter } from "./Menu";
import { useFooterBlock } from "../../hooks/use-footer-block";

export const FooterBlock = () => {
  const dataBase = useSelector(state => state.base);
  const { social_media } = useFooterBlock();

  const {
    lang: { current: langCurrent },
  } = dataBase;

  const { privacyPolicy, personalDataPolicy, disclaimer, cookieBannerText } = translations;
  const time = new Date();
  const year = time.getFullYear();

  return (
    <StyledFooter className="footer">
      <div className="element-container">
        <div className="footer_row footer_row__top">
          <div className="footer_column footer_column__wrap">
            <div className="footer_column__logo">
              <Logo inHeader={false} />
            </div>
            <div className="footer_column__social">
              {social_media &&
                social_media.map((item, index) => (
                  <a href={item.link} key={index} rel="noreferrer" target="_blank">
                    <img src={item.image.url.localFile.publicURL} alt={item.image.alt} />
                  </a>
                ))
              }
            </div>
          </div>
          <div className="footer_column footer_column__nav">
            <MenuFooter />
          </div>
        </div>
        {langCurrent === "ru" ? 
          <div className="footer_row footer_row__middle">
            <p 
              dangerouslySetInnerHTML={{
                __html: cookieBannerText.text[langCurrent],
                }}>
            </p>
          </div>
        : null }
        <div className="footer_row footer_row__bottom">
          <div className="footer_column footer_column__copyright">
            Copyright ©&nbsp;{year} Online&nbsp;Microfinance&nbsp;LLC
          </div>
          <div className="footer_column footer_column__legal-links">
            {/*
            NOTE: тут и ниже отключение уведомлений, т.к. текст вставляется
              внутрь ссылки при помощи dangerouslySetInnerHTML
            */}
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a
              href={privacyPolicy.link[langCurrent]}
              rel="noreferrer"
              target="_blank"
              dangerouslySetInnerHTML={{
                __html: privacyPolicy.text[langCurrent],
              }}
            />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a
              href={personalDataPolicy.link[langCurrent]}
              rel="noreferrer"
              target="_blank"
              dangerouslySetInnerHTML={{
                __html: personalDataPolicy.text[langCurrent],
              }}
            />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a
              href={disclaimer.link[langCurrent]}
              rel="noreferrer"
              target="_blank"
              dangerouslySetInnerHTML={{
                __html: disclaimer.text[langCurrent],
              }}
            />
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  background: ${COLORS.bg_light};
  padding: 30px 0;
  margin: 40px 0 0;

  @media (min-width: ${BREAKPOINTS.tablet}px) {
    padding: 60px 0;
    margin: 60px 0 0;
  }

  .footer_row__top {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
      flex-direction: row;
      align-items: flex-start;
    }

    .footer_column {
      width: 100%;

      @media (min-width: ${BREAKPOINTS.tablet}px) {
        width: 35%;
      }

      @media (min-width: ${BREAKPOINTS.desktop_lg}px) {
        width: 50%;
      }

      + .footer_column {
        @media (min-width: ${BREAKPOINTS.tablet}px) {
          width: 65%;
        }

        @media (min-width: ${BREAKPOINTS.desktop_lg}px) {
          width: 50%;
        }
      }
    }
  }

  .footer_column__wrap {
    @media (min-width: ${BREAKPOINTS.desktop_lg}px) {
      display: flex;
      align-items: center;
    }
  }

  .footer_column__social {
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(33, 31, 45, 0.4);

    @media (min-width: ${BREAKPOINTS.tablet}px) {
      border-bottom: none;
    }

    @media (min-width: ${BREAKPOINTS.desktop_lg}px) {
      padding: 0;
      margin-bottom: 0;
      margin-left: 20px;
    }

    a {
      margin-right: 15px;
    }
  }

  .footer_row__middle {
    p {
      font-size: 14px;
      color: rgb(137, 137, 137);
    }
  }

  .footer_row__bottom {
    display: flex;
    padding: 30px 0 0;
    border-top: 1px solid ${COLORS.lines};
    margin: 30px 0 0;

    flex-direction: column-reverse;
    align-items: flex-start;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
      padding: 0;
      border: none;
      margin: 46px 0 0;
      text-align: left;
      flex-direction: row;
    }

    .footer_column {
      width: 100%;

      @media (min-width: ${BREAKPOINTS.tablet}px) {
        width: 35%;
      }

      @media (min-width: ${BREAKPOINTS.desktop_lg}px) {
        width: 50%;
      }

      + .footer_column {
        @media (min-width: ${BREAKPOINTS.tablet}px) {
          width: 65%;
        }

        @media (min-width: ${BREAKPOINTS.desktop_lg}px) {
          width: 50%;
        }
      }
    }
  }

  .footer_column__copyright {
    margin: 15px 0 0;
    font-size: 16px;
    line-height: 25px;
    color: ${COLORS.text_secondary};

    @media (min-width: ${BREAKPOINTS.tablet}px) {
      margin: 0;
    }
  }

  .footer_column__legal-links {
    display: flex;
    font-size: 16px;
    line-height: 25px;
    flex-direction: column;

    a {
      color: ${COLORS.text_secondary};
      text-decoration: underline;

      &:hover,
      &:focus {
        color: ${COLORS.text_secondary};
        text-decoration: none;
      }

      + a {
        margin: 10px 0 0;
      }
    }
  }
`;
