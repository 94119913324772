import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { BREAKPOINTS, COLORS } from "../../settings";
import { hexToRgb } from "../../helpers/base";
import { setNavSticky, setNavUnSticky } from "../../store/actions/base";

import { Logo } from "../Logo";
import { MenuDesktop } from "./Menu";
import { Lang } from "./Lang";
import { Burger } from "./Burger";

export const HeaderBlock = () => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);

  useEffect(() => {
    setScrollPosition(window.pageYOffset);
    setPrevScrollPosition(window.pageYOffset);

    window.onscroll = function () {
      setScrollPosition(window.pageYOffset);
    };
  }, []);

  useEffect(() => {
    if (
      prevScrollPosition > scrollPosition &&
      scrollPosition < 30 &&
      dataBase.isNavSticky
    ) {
      setNavUnSticky(dispatch);
    } else if (scrollPosition >= 30 && !dataBase.isNavSticky) {
      setNavSticky(dispatch);
    }

    setPrevScrollPosition(scrollPosition);
  }, [scrollPosition]);

  return (
    <StyledHeaderBlock isSticky={dataBase.isNavSticky}>
      <div className="element-container">
        <Logo inHeader={true} />
        <MenuDesktop />
        <Lang />
        <Burger />
      </div>
    </StyledHeaderBlock>
  );
};

const StyledHeaderBlock = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 800;
  background: ${props => (props.isSticky ? "#ffffff" : "transparent")};
  transition: background 0.15s ease-out;
  box-shadow: ${props =>
    props.isSticky
      ? "0 0 10px rgba(" + hexToRgb(COLORS.bg_dark).toString() + ", .25)"
      : "none"};

  .element-container {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
      padding-top: 40px;
      padding-bottom: 40px;
      max-width: 1366px;
    }
  }
`;
