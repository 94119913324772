import { useStaticQuery, graphql } from "gatsby";

export const useFooterBlock = () => {
  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      query FooterBlock {
        wordpressAcfOptions {
          options {
            social_media {
              image {
                url {
                  localFile {
                    publicURL
                  }
                }
                alt
              }
              link
            }
          }
        }
      }
    `
  );

  return wordpressAcfOptions.options;
};
