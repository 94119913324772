import { useStaticQuery, graphql } from "gatsby";

export const useMenuDesktop = () => {
  const { ru, en, kz } = useStaticQuery(
    graphql`
      query MenuDesktop {
        ru: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 58 }) {
          name
          items {
            url
            title
            wordpress_id
          }
        }

        en: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 61 }) {
          name
          items {
            url
            title
            wordpress_id
          }
        }

        kz: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 62 }) {
          name
          items {
            url
            title
            wordpress_id
          }
        }
      }
    `
  );

  const menuRuItems = ru.items;
  const menuEnItems = en.items;
  const menuKzItems = kz.items;

  return { menuRuItems, menuEnItems, menuKzItems };
};
