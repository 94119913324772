export const updateLang = (dispatch, data) => {
    dispatch({
        type: 'UPDATE_LANG',
        data,
    });
};

export const updateLangListState = (dispatch) => {
    dispatch({
        type: 'UPDATE_LANG_LIST_STATE',
    });
};

export const updateLangs = (dispatch, data) => {
    dispatch({
        type: 'UPDATE_LANGS',
        data,
    });
};

export const updatePageType = (dispatch, data) => {
    dispatch({
        type: 'UPDATE_PAGE_TYPE',
        data,
    });
};

export const updateHeaderStyle = (dispatch, data) => {
    dispatch({
        type: 'UPDATE_HEADER_STYLE',
        data,
    });
};

export const updateSidebarNavState = (dispatch) => {
    dispatch({
        type: 'UPDATE_SIDEBAR_NAV_STATE',
    });
};

export const updateSidebarSubnavState = (dispatch, data) => {
    dispatch({
        type: 'UPDATE_SIDEBAR_SUBNAV_STATE',
        data,
    });
};

export const updateFooterSubnavState = (dispatch, data) => {
    dispatch({
        type: 'UPDATE_FOOTER_SUBNAV_STATE',
        data,
    });
};

export const updateModalState = (dispatch, type) => {
    dispatch({
        type: 'UPDATE_MODAL_STATE',
        data: type,
    });
};

export const updateSubscriberModalState = (dispatch, data) => {
    dispatch({
        type: 'UPDATE_SUBSCRIBE_MODAL_STATE',
        data,
    });
};

export const setNavSticky = (dispatch) => {
    dispatch({
        type: 'SET_NAV_STICKY',
    });
};

export const setNavUnSticky = (dispatch) => {
    dispatch({
        type: 'SET_NAV_UNSTICKY',
    });
};

export const setIP = (dispatch, ip) => {
    dispatch({
        type: 'SET_IP',
        data: ip,
    });
};

export const setOldUserData = (dispatch, oldUserData) => {
    dispatch({
        type: 'SET_OLD_USER_DATA',
        data: oldUserData,
    });
};

export const setManagerBtn = (dispatch, managerData) => {
    dispatch({
        type: 'SET_MANAGER_DATA',
        data: managerData,
    });
};
