import React from "react";
import { useSelector } from "react-redux";
import { Link } from "gatsby";
import styled from "styled-components";

import { SETTINGS, COLORS, BREAKPOINTS } from "../../../settings";
import { langGetNavItems } from "../../../helpers/lang";
import { useMenuDesktop } from "../../../hooks/use-menu-desktop";

export const MenuDesktop = () => {
  const dataBase = useSelector(state => state.base);

  const {
    lang: { current: langCurrent },
    pages: { isPageMain },
  } = dataBase;

  const itemsAll = useMenuDesktop();
  const items = langGetNavItems(itemsAll, "menu", langCurrent);

  return langCurrent !== null && isPageMain !== null ? (
    <>
      {items && (
        <StyledMenu
          className="menu menu-desktop"
          isLightHeader={dataBase.isLightHeader}
          isSticky={dataBase.isNavSticky}
        >
          {items.map(item => {
            const path = item.url.replace(SETTINGS.URL_MASTER, "");

            return (
              <li key={item.wordpress_id} className="menu_item">
                <Link
                  to={path}
                  className="menu_link"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                  partiallyActive={true}
                  activeClassName="menu_link__active"
                />
              </li>
            );
          })}
        </StyledMenu>
      )}
    </>
  ) : null;
};

const StyledMenu = styled.ul`
  display: none;

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    display: flex;
    align-items: center;
    margin-left: 40px;
  }

  .menu_item {
    font-size: 16px;
    line-height: 25px;

    + .menu_item {
      margin: 0 0 0 25px;
    }
  }

  .menu_link {
    color: ${props =>
      props.isLightHeader && !props.isSticky
        ? COLORS.text_inverted
        : COLORS.text};

        &__active {
          text-decoration: underline;
        }
  }
`;
