import React from "react";
import { useSelector } from "react-redux";
import { Link } from "gatsby";
import styled from "styled-components";

import { translator } from "../../helpers/lang";
import { useThemeSettings } from "../../hooks/use-theme-settings";

export const Logo = ({ inHeader, inMenu }) => {
  const { logo_light, logo_dark } = useThemeSettings();
  const dataBase = useSelector(state => state.base);

  const {
    lang: { current: langCurrent },
    pages: { isPageMain },
  } = dataBase;

  const logo = () => {
    return (dataBase.isLightHeader && inHeader && !dataBase.isNavSticky) ||
      inMenu
      ? logo_light && logo_light.localFile && logo_light.localFile.publicURL
      : logo_dark && logo_dark.localFile && logo_dark.localFile.publicURL;
  };

  return isPageMain !== null ? (
    <StyledLogo
      to={translator(langCurrent, {
        ru: "/",
        en: "/en/",
        kz: "/kz/",
      })}
      className="logo"
    >
      <img src={logo()} alt="IDF Eurasia" />
    </StyledLogo>
  ) : null;
};

const StyledLogo = styled(Link)`
  margin: 0 auto 0 0;
  text-decoration: none;

  img {
    display: block;
  }
`;
