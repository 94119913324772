import { capitalize } from "./base";

const langCodeTransform = langCode => {
  const langCodeUpd = langCode.slice(0, 2).toLowerCase();

  if (langCodeUpd === "kk") {
    return "kz";
  }

  return langCodeUpd;
};

export const translator = (langCode, translations) => {
  let result = translations[langCode];
  if (result === undefined) {
    result = translations["en"];
  }

  return result;
};

export const langLinkClass = (langCurrent, langCode) => {
  const baseClasses = "lang-list_link";

  return langCurrent === langCode
    ? baseClasses + " lang-list_link__active"
    : baseClasses;
};

export const langNormalise = langCode => {
  return langCodeTransform(langCode);
};

export const langsNormalise = (langsData, langCurrent, langsWhiteList) => {
  const langsDataUpd = [];

  langsWhiteList.forEach(lang => {
    const langIndex = langsData.findIndex(element => {
      return langCodeTransform(element.polylang_current_lang) === lang.langCode;
    });
    const langData = {
      langCode: lang.langCode,
      name: lang.langName[langCurrent],
    };

    if (langIndex !== -1 && langsData[langIndex].status === "publish") {
      langData.link =
        lang.langCode === langCurrent ? null : langsData[langIndex].path;
    } else {
      langData.link = lang.langCode !== "ru" ? "/" + lang.langCode + "/" : "/";
    }

    langsDataUpd.push(langData);
  });

  return langsDataUpd;
};

export const langGetName = langsNormalisedData => {
  let result = langsNormalisedData.current.toUpperCase();

  langsNormalisedData.all.forEach(lang => {
    if (langsNormalisedData.current === lang.langCode) {
      result = lang.name;
    }
  });

  return result;
};

// TODO: объеденить langGetNavItems, langGetPostItems, langGetProjectItems в одну функцию
export const langGetNavItems = (itemsAll, namePrefix, langCurrent) => {
  return langCurrent
    ? itemsAll[namePrefix + capitalize(langCurrent) + "Items"]
    : null;
};

export const langGetPostItems = (itemsAll, namePrefix, langCurrent) => {
  return langCurrent
    ? itemsAll[namePrefix + capitalize(langCurrent) + "Items"]
    : null;
};

export const langGetProjectItems = (itemsAll, namePrefix, langCurrent) => {
  return langCurrent
    ? itemsAll[namePrefix + capitalize(langCurrent) + "Items"]
    : null;
};

//historyRuNodes
export const langGetHistoryItems = (itemsAll, namePrefix, langCurrent) => {
  return langCurrent
    ? itemsAll[namePrefix + capitalize(langCurrent) + "Nodes"]
    : null;
};

//awardsRuNodes
export const langGetAwardsItems = (itemsAll, namePrefix, langCurrent) => {
  return langCurrent
    ? itemsAll[namePrefix + capitalize(langCurrent) + "Nodes"]
    : null;
};
