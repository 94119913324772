import { useStaticQuery, graphql } from "gatsby";

export const useMenuFooter = () => {
  const {
    column1Ru,
    column1En,
    column1Kz,
    column2Ru,
    column2En,
    column2Kz,
    column3Ru,
    column3En,
    column3Kz,
  } = useStaticQuery(
    graphql`
      query MenuFooter {
        column1Ru: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 55 }) {
          name
          items {
            wordpress_id
            title
            url
          }
        }
        column1En: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 69 }) {
          name
          items {
            wordpress_id
            title
            url
          }
        }
        column1Kz: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 68 }) {
          name
          items {
            wordpress_id
            title
            url
          }
        }

        column2Ru: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 56 }) {
          name
          items {
            wordpress_id
            title
            url
          }
        }
        column2En: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 71 }) {
          name
          items {
            wordpress_id
            title
            url
          }
        }
        column2Kz: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 70 }) {
          name
          items {
            wordpress_id
            title
            url
          }
        }

        column3Ru: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 57 }) {
          name
          items {
            url
            title
            wordpress_id
          }
        }
        column3En: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 73 }) {
          name
          items {
            url
            title
            wordpress_id
          }
        }
        column3Kz: wordpressWpApiMenusMenusItems(wordpress_id: { eq: 72 }) {
          name
          items {
            url
            title
            wordpress_id
          }
        }
      }
    `
  );

  const column1RuItems = column1Ru.items;
  const column1EnItems = column1En.items;
  const column1KzItems = column1Kz.items;
  const column2RuItems = column2Ru.items;
  const column2EnItems = column2En.items;
  const column2KzItems = column2Kz.items;
  const column3RuItems = column3Ru.items;
  const column3EnItems = column3En.items;
  const column3KzItems = column3Kz.items;

  return {
    column1RuItems,
    column1EnItems,
    column1KzItems,
    column2RuItems,
    column2EnItems,
    column2KzItems,
    column3RuItems,
    column3EnItems,
    column3KzItems,
  };
};
