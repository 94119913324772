export const addItemClass = (haveChildren, submenuIsOpen) => {
  const baseClass = "menu_item";

  let allClass = baseClass;
  allClass = haveChildren ? allClass + " menu_item__folder" : allClass;
  // allClass = submenuIsOpen && haveChildren
  //   ? allClass + " menu_item__folder-open"
  //   : allClass;

  return allClass;
};

export const addLinkClass = ({ isCurrent, isPartiallyCurrent }) => {
  const baseClass = "menu_link";

  return isCurrent || isPartiallyCurrent
    ? { className: baseClass + " menu_link__active" }
    : { className: baseClass };
};
