import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { BREAKPOINTS, COLORS, TRANSLATIONS } from "../../../settings";
import { updateSidebarNavState } from "../../../store/actions/base";

export const Burger = () => {
  const dispatch = useDispatch();
  const dataBase = useSelector(state => state.base);

  const {
    lang: { current: langCurrent },
    pages: { isPageMain, isPageError },
  } = dataBase;
  const { buttons } = TRANSLATIONS;

  const ClickHandler = () => {
    updateSidebarNavState(dispatch);
  };

  return langCurrent !== null && isPageMain !== null && !isPageError ? (
    <StyledButton
      type="button"
      onClick={ClickHandler}
      className="burger-wrapper"
      isLightHeader={dataBase.isLightHeader}
      isSticky={dataBase.isNavSticky}
      aria-label={buttons.burger[langCurrent]}
    >
      <StyledBurger className="burger" ariaHidden="true">
        <div className="burger_line burger_line__top" />
        <div className="burger_line burger_line__cutlet" />
        <div className="burger_line burger_line__bottom" />
      </StyledBurger>
      <StyledText
        isLightHeader={dataBase.isLightHeader}
        dangerouslySetInnerHTML={{ __html: buttons.burger[langCurrent] }}
      />
    </StyledButton>
  ) : null;
};

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  margin: 0 0 0 20px;
  padding: 0;
  border: none;
  color: ${props =>
    props.isLightHeader && !props.isSticky
      ? COLORS.text_inverted
      : COLORS.text};
  font-size: 16px;
  line-height: 25px;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    margin: 0 0 0 37px;
  }
`;

const StyledBurger = styled.div`
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  padding: 5px 2px;

  .burger_line {
    position: absolute;
    top: 5px;
    left: 2px;
    width: 20px;
    height: 2px;
    background: currentColor;
    border-radius: 2px;
    transition: width 0.15s ease-out;

    &__top {
      right: 2px;
      left: auto;
      width: 10px;
    }

    &__cutlet {
      top: 11px;
    }

    &__bottom {
      top: auto;
      bottom: 5px;
      width: 14px;
    }
  }

  ${StyledButton}:hover &,
  ${StyledButton}:focus & {
    .burger_line {
      width: 20px;
    }
  }
`;

const StyledText = styled.div`
  display: none;
  margin: 0 0 0 12px;

  @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
    display: block;
  }

  @media (min-width: ${BREAKPOINTS.desktop + "px"}) {
    display: ${props => (props.isLightHeader ? "none" : "block")};
  }
`;
